<script setup lang="ts">
import { computed } from 'vue';
import { PaymentType } from '@/generated/payment-api';
import MRadio from '@/components/common/MRadio.vue';

const props = defineProps<{
  modelValue: string;
  paymentTypes: PaymentType[];
  projectType: string;
}>();

const emit = defineEmits<{
  'update:modelValue': [value?: string];
}>();

const value = computed({
  get: () => props.modelValue,
  set: (v: string) => emit('update:modelValue', v),
});

const radioOptions = computed(() => {
  return props.paymentTypes.map((type) => {
    switch (type) {
      case PaymentType.CARD:
        return {
          type: 'card',
          label: 'クレジットカード',
          value: '/input/credit',
        };
      case PaymentType.CVS:
        return { type: 'cvs', label: 'コンビニ決済', value: '/input/cvs' };
      case PaymentType.PAYEASY:
        return {
          type: 'payeasy',
          label: 'ペイジー決済（Pay-easy）',
          value: '/input/payeasy',
        };
      case PaymentType.GANB:
        return {
          type: 'ganb',
          label: '銀行振込（GMOあおぞらネット銀行 バーチャル口座）',
          value: '/confirm',
        };
      default:
        return { type: '', label: '', value: '' };
    }
  });
});

// TODO: 2025/04/14 10:00以降に削除
const showFamilyMart = () => {
  const now = new Date();
  const fromDate = new Date('2025-04-14T01:00:00');
  const toDate = new Date('2025-04-14T06:30:00');
  if (fromDate <= now && now <= toDate) {
    return false;
  } else {
    return true;
  }
};
</script>

<template>
  <div class="box">
    <div class="payment-type">
      <MRadio v-model="value" :name="'payment-type'" :options="radioOptions">
        <template #slot-card v-if="paymentTypes.includes(PaymentType.CARD)">
          <div class="payment-type-content brand-logo-credit">
            <img
              v-if="projectType === 'CROWDFUNDING'"
              src="@/assets/img/card_brand_logo.png"
            />
            <img
              v-else-if="projectType === 'DONATION'"
              src="@/assets/img/card_brand_logo_gcf.png"
            />
          </div>
        </template>
        <template #slot-cvs>
          <template v-if="paymentTypes.includes(PaymentType.CVS)">
            <div class="payment-type-content brand-logo-cvs">
              <img
                id="lawson"
                class="brand-logo-lawson"
                src="@/assets/img/lawson_logo.gif"
              />
              <img
                id="familymart"
                class="brand-logo-familymart"
                src="@/assets/img/familymart_logo.jpg"
                v-show="showFamilyMart()"
              />
              <img
                id="ministop"
                class="brand-logo-ministop"
                src="@/assets/img/ministop_logo.gif"
              />
              <img
                id="seicomart"
                class="brand-logo-seicomart"
                src="@/assets/img/seicomart_logo.gif"
              />
            </div>
          </template>
        </template>
        <template #slot-payeasy>
          <template v-if="paymentTypes.includes(PaymentType.PAYEASY)">
            <div class="payment-type-content sub-text">
              <p>■お支払い可能な金融機関</p>
              <p>
                ゆうちょ銀行・みずほ銀行・三井住友銀行・三菱UFJ銀行・りそな銀行・楽天銀行
                他
              </p>
            </div>
          </template>
        </template>
        <template #slot-ganb>
          <template v-if="paymentTypes.includes(PaymentType.GANB)">
            <div class="payment-type-content sub-text">
              <p>※振込手数料は購入者様負担になりますので、ご了承ください。</p>
            </div>
          </template>
        </template>
      </MRadio>
    </div>
  </div>
</template>

<style scoped>
.box {
  line-height: 1.8;
  padding: 0 20px 10px;
}

.payment-type {
  margin-bottom: 16px;
}

.payment-type-content {
  margin: 8px 0 8px 32px;
}

.brand-logo-credit {
  max-height: 40px;
  max-width: 312px;
  display: flex;
  justify-content: flex-start;
}

.brand-logo-credit img {
  max-height: 40px;
  max-width: 312px;
}

@media (max-width: 667px) {
  .brand-logo-credit img {
    max-width: 100%;
  }

  .brand-logo-credit {
    width: 100%;
    box-sizing: border-box;
  }
}

.brand-logo-cvs {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.brand-logo-lawson,
.brand-logo-familymart,
.brand-logo-seicomart {
  margin-bottom: 4px;
  margin-right: 8px;
  width: 100px;
}

.brand-logo-ministop {
  margin-bottom: 4px;
  margin-right: 8px;
  width: 50px;
}

.sub-text {
  font-size: 14px;
}
</style>
