<script setup lang="ts">
import { inject, onMounted, ref } from 'vue';
import type { AxiosError } from 'axios';
import PaymentLayout from '@/components/common/PaymentLayout.vue';
import { MText, MSelect } from '@ca-crowdfunding/makuake-ui-n';
import { MTextField } from '@ca-crowdfunding/makuake-ui-n';
import Buttons from '@/components/common/Buttons.vue';
import router from '@/router';
import {
  TranService,
  type TranCheckRequest,
  type TranTemporaryResponse,
  PaymentType,
  ErrorCode,
  type Error,
  CvsCode,
} from '@/generated/payment-api';
import { type errorStore, errorStoreKey } from '@/store/error';
import { type activityStore, activityStoreKey } from '@/store/activity';
import { isTimeoutError } from '@/generated/payment-api/core/request';

const isLoadingButton = ref<boolean>(false);
const cvs = ref<{ cvs_code: string }>({
  cvs_code: '',
});
const payer = ref<{ name: string; nameKana: string; phoneNumber: string }>({
  name: '',
  nameKana: '',
  phoneNumber: '',
});

const store = inject(errorStoreKey) as errorStore;
const activity = inject(activityStoreKey) as activityStore;
const errorMessage = ref<string[]>([]);
const cvsOptions = ref<{ label: string; value: string }[]>([
  {
    label: '選択してください',
    value: '',
  },
  {
    label: 'ローソン',
    value: CvsCode.LAWSON,
  },
  {
    label: 'ファミリーマート',
    value: CvsCode.FAMILYMART,
  },
  {
    label: 'ミニストップ',
    value: CvsCode.MINISTOP,
  },
  {
    label: 'セイコーマート',
    value: CvsCode.SEICOMART,
  },
]);

onMounted(() => {
  isLoadingButton.value = true;
  if (store.code !== '') {
    router.push(`/error`);
    return;
  }
  if (!showFamilyMart()) {
    cvsOptions.value = cvsOptions.value.filter(
      (option) => option.label !== 'ファミリーマート',
    );
  }
  // temporaryにデータが無い場合、/tarn/temporaryにリクエストしない
  // データが無い場合、/tarn/temporaryがエラーを返却する為、エラー監視のノイズになるため
  if (!activity.hasTranTemporary) {
    isLoadingButton.value = false;
    return;
  }
  TranService.tranTemporary()
    .then((response: TranTemporaryResponse) => {
      cvs.value.cvs_code = response.cvs_code ?? '';
      payer.value.name = response.payer?.name ?? '';
      payer.value.nameKana = response.payer?.name_kana ?? '';
      payer.value.phoneNumber = response.payer?.phone_number ?? '';
    })
    .catch((error: AxiosError<Error, any>) => {
      if (!error.response) {
        return;
      }
      const { status } = error.response;
      const { code } = error.response.data;
      if (status === 401 && code === ErrorCode.AUTHENTICATION_ERROR) {
        window.location.assign(import.meta.env.VITE_MAKUAKE_URL + '/login/');
        return;
      }
      const isTimeOut =
        (status === 403 && code === ErrorCode.SESSION_TIMEOUT) ||
        (status === 403 && code === ErrorCode.PAYMENT_TIMEOUT);
      if (isTimeOut) {
        router.push(`/error`);
        return;
      }
      // AUTHENTICATION_ERROR/SESSION_TIMEOUT/PAYMENT_TIMEOUT 以外のエラーはハンドリングしない
    })
    .finally(() => {
      isLoadingButton.value = false;
    });
});

// TODO: 2025/04/14 10:00以降に削除
const showFamilyMart = () => {
  const now = new Date();
  const fromDate = new Date('2025-04-14T01:00:00');
  const toDate = new Date('2025-04-14T06:30:00');
  if (fromDate <= now && now <= toDate) {
    return false;
  } else {
    return true;
  }
};

const handleSelectCvs = (event: Event) => {
  const target = event.target as HTMLSelectElement;
  cvs.value.cvs_code = target.value;
};

const handleInputName = (event: Event) => {
  const target = event.target as HTMLSelectElement;
  payer.value.name = target.value;
};

const handleInputNameKana = (event: Event) => {
  const target = event.target as HTMLSelectElement;
  payer.value.nameKana = target.value;
};

const handleInputPhoneNumber = (event: Event) => {
  const target = event.target as HTMLSelectElement;
  payer.value.phoneNumber = target.value;
};

const handleNegative = () => {
  isLoadingButton.value = true;
  router.push('/select');
};

const handlePositive = () => {
  isLoadingButton.value = true;
  payer.value.name = payer.value.name.trim();
  payer.value.nameKana = payer.value.nameKana.trim();
  payer.value.phoneNumber = payer.value.phoneNumber.trim();
  const body: TranCheckRequest = {
    payment_type: PaymentType.CVS,
    cvs: {
      cvs_code: cvs.value.cvs_code as CvsCode,
    },
    payer: {
      name: payer.value.name,
      name_kana: payer.value.nameKana,
      phone_number: payer.value.phoneNumber,
    },
  };
  TranService.tranCheck(body)
    .then(() => {
      activity.succeedCreateTemporary();
      router.push(`/confirm`);
    })
    .catch((error: AxiosError<Error, any>) => {
      isLoadingButton.value = false;
      activity.failedCreateTemporary();
      if (!error.response) {
        isTimeoutError(error, store, false) ? router.push(`/error`) : null;
        return;
      }
      const { status } = error.response;
      const { code, message, details } = error.response.data;

      if (status === 400 && code === ErrorCode.INVALID_ARGUMENT && details) {
        errorMessage.value = Object.values(details);
        window.scrollTo(0, 0);
        return;
      }
      if (status === 401 && code === ErrorCode.AUTHENTICATION_ERROR) {
        window.location.assign(import.meta.env.VITE_MAKUAKE_URL + '/login/');
        return;
      }
      store.code = code;
      store.messages = [message];
      router.push(`/error`);
    });
};
</script>

<template>
  <PaymentLayout
    :step="2"
    title="コンビニ決済の必要事項を入力してください"
    :errorMessages="errorMessage"
  >
    <template v-slot:contents>
      <MText
        align="left"
        color="primary"
        size="medium"
        tag="div"
        weight="bold"
        class="title"
      >
        ご利用するコンビニの選択
      </MText>
      <div class="item-field">
        <MSelect
          name="cvs"
          :options="cvsOptions"
          @input="handleSelectCvs"
          v-model="cvs.cvs_code"
        />
      </div>

      <MText
        align="left"
        color="primary"
        size="medium"
        tag="div"
        weight="bold"
        class="title"
      >
        お名前
      </MText>
      <div class="item-field">
        <MTextField
          id="name"
          name="name"
          type="text"
          placeholder="幕開太郎"
          maxlength="15"
          @input="handleInputName"
          v-model="payer.name"
        />
      </div>

      <MText
        align="left"
        color="primary"
        size="medium"
        tag="div"
        weight="bold"
        class="title"
      >
        フリガナ（全角）
      </MText>
      <div class="item-field">
        <MTextField
          id="name-kana"
          name="name-kana"
          type="text"
          placeholder="マクアケタロウ"
          maxlength="15"
          @input="handleInputNameKana"
          v-model="payer.nameKana"
        />
      </div>

      <MText
        align="left"
        color="primary"
        size="medium"
        tag="div"
        weight="bold"
        class="title"
      >
        電話番号（ハイフンなし）
      </MText>
      <div class="item-field">
        <MTextField
          id="phone-number"
          name="phone-number"
          type="text"
          placeholder="08012345678"
          maxlength="11"
          @input="handleInputPhoneNumber"
          v-model="payer.phoneNumber"
        />
      </div>
    </template>
    <template v-slot:buttons>
      <MSpinner v-if="isLoadingButton" size="medium" />
      <Buttons
        v-if="!isLoadingButton"
        :handleNegative="handleNegative"
        :handlePositive="handlePositive"
      />
    </template>
  </PaymentLayout>
</template>

<style scoped>
.title {
  margin: 0 0 14px 0;
}
.item-field {
  line-height: 1.8;
  margin: 5px 0 25px;
  padding: 8px;
}
</style>
